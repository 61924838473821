import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(router)


import axios from 'axios'
import VueAxios from 'vue-axios'

const axios_instance = axios.create({
    // baseURL: 'http://localhost/wheelerng/index.php/'
    baseURL: 'https://logistics.wheeler.ng/wheeler/index.php'
});

app.use(VueAxios, axios_instance)
app.provide('axios', app.config.globalProperties.axios)



import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const options = {
    // You can set your default options here
};
app.use(Toast, options);


import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

app.use(TawkMessengerVue, {
    propertyId : '662a36ffa0c6737bd1303e52',
    widgetId : '1hsaedm91'
});


app.mount('#app')
