<template>
    <div class="track">
        <headerVue/>
        <div class="main">
            <div class="title">Track Shipment</div>
            <div class="text">See details about your shipment</div>

            
            <track_details_view v-if="show_details" 
                :shipment_id="shipment_id"
                :shipment="shipment"
                :wheeler_response="wheeler_response"
                :items_picked="items_picked"
                :delivering="delivering"
                :transit="transit"
                :delivered="delivered"
                :received="received"
            />

            <div class="input_box" v-else>
                <div class="input_title">Enter your Shipment ID</div>
                <input type="text" placeholder="Shipment / Tracking ID" v-model="id">
                
                <button_loading :height="'46px'" v-if="loading"/>
                <div class="btn" @click="track_clicked()" v-else>Search</div>
            </div>

        </div>

        <footerVue />
    </div>
</template>

<script setup>
import headerVue from "@/components/header.vue";
import footerVue from "@/components/footer.vue";

import button_loading from '@/components/loading.vue'

import track_details_view from '@/components/track/details/view.vue'

import { useRoute, useRouter } from "vue-router";
import { useToast } from "vue-toastification";
import { ref, inject } from "vue";


const route = useRoute()
const id = ref('')
const show_details = ref(false)



const shipment_id = ref(null)
const shipment = ref(null)
const wheeler_response = ref(null)
const items_picked = ref(null)
const delivering = ref(null)
const transit = ref(null)
const delivered = ref(null)
const received = ref(null)


const loading = ref(false)


const toast = useToast()
const axios = inject('axios')
const router = useRouter()


const track_clicked = () => {
    if (id.value == '') {
        toast.error('Enter your Shipment / Tracking ID')
        return
    }

    track_shipment(id.value)
}


const track_shipment = (id) => {

    loading.value = true

    shipment_id.value = id
    
    let form = new FormData()
    form.append('shipment_id', id)

    axios.post('/shipment/track', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            
            router.push('/track?id=' + r.shipment_id)

            shipment.value = r.shipment
            wheeler_response.value = r.wheeler_response
            items_picked.value = r.items_picked
            delivering.value = r.delivering
            transit.value = r.transit
            delivered.value = r.delivered
            received.value = r.received

            loading.value = false
            show_details.value = true

        } else {
            toast.error('The Tacking ID provided is incorrect')
            router.push('/track')
            setTimeout(() => {
                router.go()
            }, 1000);
        }
    })
}


const init = () => {

window.scrollTo({
    'top': 0,
    'behavior': 'smooth'
})

let fullpath = route.fullPath
if (fullpath.includes('?')) {
    let query = fullpath.split('?')[1]

    if (query.includes('=')) {
        id.value = query.split('=')[1]
        track_shipment(id.value)
    }
}
}
init()

</script>

<style scoped>
    
    .main {
        padding: 50px 20px;
        padding-bottom: 50px;
    }
    .title {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
    }
    .text {
        text-align: center;
        font-size: 14px;
    }


    .input_box {
        background-color: #f8f8f8;
        border-radius: 20px;
        padding: 30px;
        box-sizing: border-box;
        max-width: 400px;
        width: 100%;
        margin: 0 auto;
        margin-top: 100px;
    }

    .input_title {
        /* font-weight: 500; */
        font-size: 18px;
        margin-bottom: 20px;
    }

    input {
        padding: 15px;
        border: 1px solid #ddd;
        border: none;
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }
    
    .btn {
        padding: 12px;
        border-radius: 5px;
        background-color: var(--main);
        cursor: pointer;
        color: white;
        margin: 0 auto;
        text-align: center;
        font-weight: 500;
    }
</style>