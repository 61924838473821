<template>
    <div class="express">
        <headerVue/>
        <div class="main">
            <div class="title">Book with us today</div>
            <div class="text">Get your items shipped now</div>

            <div class="forms">
                <div class="form">
                    <div class="form_label">Sender Info</div>
                    <div class="input">
                        <div class="label">Email</div>
                        <input type="text" placeholder="Enter your email" v-model="email">
                    </div>
                    <div class="input">
                        <div class="label">Full Name</div>
                        <input type="text" placeholder="Enter your full name" v-model="name">
                    </div>
                    <div class="input">
                        <div class="label">Phone Number (Whatsapp)</div>
                        <input type="text" placeholder="Enter your whatsapp number" v-model="phone">
                    </div>
                    <div class="input">
                        <div class="label">Address</div>
                        <input type="text" placeholder="Enter your address" v-model="address">
                    </div>
                    <div class="input">
                        <div class="label">State</div>
                        <select v-model="state">
                            <option value="" selected disabled>Select your state</option>
                            <option v-for="s in states" :key="s" :value="s">{{ s }}</option>
                        </select>
                    </div>
                    <div class="input">
                        <div class="label">City</div>
                        <input type="text" placeholder="Enter your city" v-model="city">
                    </div>

                    <div class="form_label">Receiver Info</div>
                    <div class="input">
                        <div class="label">Receiver's Full Name</div>
                        <input type="text" placeholder="Enter receiver full name" v-model="receiver_name">
                    </div>
                    <div class="input">
                        <div class="label">Receiver's Phone Number</div>
                        <input type="text" placeholder="Enter receiver phone number" v-model="receiver_phone">
                    </div>
                    <div class="input">
                        <div class="label">Destination Address</div>
                        <input type="text" placeholder="Enter receiver address" v-model="receiver_address">
                    </div>
                    <div class="input">
                        <div class="label">Destination State</div>
                        <select v-model="receiver_state">
                            <option value="" selected disabled>Select receiver state</option>
                            <option v-for="s in states" :key="s" :value="s">{{ s }}</option>
                        </select>
                    </div>
                    <div class="input">
                        <div class="label">Destination City</div>
                        <input type="text" placeholder="Enter receiver city" v-model="receiver_city">
                    </div>
                    <div class="form_label">Item Info</div>
                    <div class="input">
                        <div class="label">Item Category</div>
                        <select v-model="item_category">
                            <option value="" selected disabled>Select item category</option>
                            <option v-for="s in category" :key="s" :value="s">{{ s }}</option>
                        </select>
                    </div>
                    <div class="input">
                        <div class="label">Item Name</div>
                        <input type="text" placeholder="Enter item name" v-model="item_name">
                    </div>
                    <div class="input">
                        <div class="label">Weight (KG)</div>
                        <input type="number" placeholder="Enter weight" v-model="item_weight">
                    </div>
                    <div class="input">
                        <div class="label">Value (Naira)</div>
                        <input type="number" placeholder="Enter items value in Naira" v-model="item_value">
                    </div>

                    <button_loading :height="'46px'" v-if="loading"/>
                    <div class="btn" @click="proceed()" v-else>Proceed</div>
                </div>
            </div>
        </div>
        
        <footerVue />
    </div>
</template>

<script setup> 
import headerVue from "@/components/header.vue";

import button_loading from '@/components/loading.vue'

import footerVue from "@/components/footer.vue";

import { inject, ref } from "vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

const states = ref([
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Federal Capital Territory"
])
const category = ref([
    'Food', 'Computer Accessories', 'Phone', 'Documents', 'Electronics', 'Health Products', 
    'Jewelries/Accessories', 'Others'
])


const email = ref('')
const name = ref('')
const phone = ref('')
const address = ref('')
const state = ref('')
const city = ref('')

const receiver_name = ref('')
const receiver_phone = ref('')
const receiver_address = ref('')
const receiver_state = ref('')
const receiver_city = ref('')

const item_category = ref('')
const item_name = ref('')
const item_weight = ref('')
const item_value = ref('')

const loading = ref(false)

const toast = useToast()
const axios = inject('axios')
const router = useRouter()

const proceed = () => {
    if (email.value == '' || name.value == '' || phone.value == '' || address.value == '' || state.value == '' || 
        city.value == '' ||
    receiver_name.value == '' || receiver_phone.value == '' || receiver_address.value == '' || 
    receiver_state.value == '' || receiver_city.value == '' ||
    item_category.value == '' || item_name.value == '' || item_weight.value == '' || item_value.value == '') {
        toast.error('Make sure all field are filled')
        return
    }

    loading.value = true

    let form = new FormData()
    form.append('email', email.value)
    form.append('name', name.value)
    form.append('phone', phone.value)
    form.append('address', address.value)
    form.append('state', state.value)
    form.append('city', city.value)

    form.append('receiver_name', receiver_name.value)
    form.append('receiver_phone', receiver_phone.value)
    form.append('receiver_address', receiver_address.value)
    form.append('receiver_state', receiver_state.value)
    form.append('receiver_city', receiver_city.value)

    form.append('item_category', item_category.value)
    form.append('item_name', item_name.value)
    form.append('item_weight', item_weight.value)
    form.append('item_value', item_value.value)

    axios.post('/shipment/new', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            toast.success('Your shipment has been submitted, you will be contacted soon')

            router.push('/track?id=' + r.shipment_id)
        }
    })
}


</script>

<style scoped> 
    .main {
        padding: 50px 20px;
        padding-bottom: 50px;
    }
    .title {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
    }
    .text {
        text-align: center;
        font-size: 14px;
    }

    .forms {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
        margin-top: 40px;
    }
    @media screen and (max-width: 1050px) {
        .forms {
            flex-direction: column;
        }
    }

    .form {
        /* border: 1px solid #ddd; */
        background-color: #f8f8f8;
        border-radius: 20px;
        padding: 30px;
        box-sizing: border-box;

        max-width: 550px;
        width: 100%;
        margin: 0 auto;
    }
    .form_label {
        background-color: var(--main);
        color: white;
        padding: 15px 30px;
        padding-left: 30px;
        margin-left: -30px;
        margin-bottom: 20px;
        width: fit-content;
    }

    .input {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        font-weight: 500;
        /* color: gray; */
        padding-left: 5px;
        margin-bottom: 5px;
    }
    input, select, textarea {
        padding: 15px;
        border: 1px solid #ddd;
        border: none;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .btn {
        padding: 12px;
        border-radius: 5px;
        background-color: var(--main);
        cursor: pointer;
        color: white;
        margin: 0 auto;
        text-align: center;
        font-weight: 500;
    }


    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .form_title {
        font-size: 18px;
        font-weight: 700;
    }
    .add {
        background-color: var(--main);
        color: white;
        padding: 10px 25px;
        border-radius: 5px;
        cursor: pointer;
    }
    .empty {
        padding: 30px 0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
        fill: #333;
        font-size: 14px;
        color: gray;
    }
</style>