<template>
    <div class="home">
        <div class="tt">Admin Home</div>
    </div>
</template>

<script scoped>

</script>

<style scoped>
    .home {
        padding: 20px;
    }
    .tt {
        font-size: 18px;
        font-weight: 600;
    }
</style>