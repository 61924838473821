<template>
    <div class="express">
        <headerVue/>
        <div class="main">
            <div class="title">LIst Your Car</div>
            <div class="text">Wheeler Car owners Onboarding Form</div>

            <div class="forms">
                <div class="form">

                    <div class="form_label">Personal Information</div>
                    <div class="input">
                        <div class="label">Email</div>
                        <input type="text" placeholder="Enter your email" v-model="email">
                    </div>
                    <div class="input">
                        <div class="label">Full Name</div>
                        <input type="text" placeholder="Enter your full name" v-model="name">
                    </div>
                    <div class="input">
                        <div class="label">Date of Birth</div>
                        <input type="date" placeholder="Enter your date of birth" v-model="d_o_b">
                    </div>
                    <div class="input">
                        <div class="label">Gender</div>
                        <select v-model="gender">
                            <option value="" selected disabled>Select your gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="other">Other</option>
                        </select>
                    </div>
                    <div class="input">
                        <div class="label">Phone Number (Whatsapp)</div>
                        <input type="text" placeholder="Enter your whatsapp number" v-model="phone">
                    </div>
                    <div class="input">
                        <div class="label">Address</div>
                        <input type="text" placeholder="Enter your address" v-model="address">
                    </div>
                    <div class="input">
                        <div class="label">State</div>
                        <select v-model="state">
                            <option value="" selected disabled>Select your state</option>
                            <option v-for="s in states" :key="s" :value="s">{{ s }}</option>
                        </select>
                    </div>
                    <div class="input">
                        <div class="label">City</div>
                        <input type="text" placeholder="Enter your city" v-model="city">
                    </div>


                    <div class="form_label">Driving Information</div>
                    <div class="input">
                        <div class="label">Driver's License Number</div>
                        <input type="text" placeholder="Enter your driver's license number" v-model="driver_license">
                    </div>
                    

                    <div class="form_label">Vehicle Information</div>
                    <div class="input">
                        <div class="label">Car Make</div>
                        <input type="text" placeholder="Enter your car make" v-model="car_make">
                    </div>
                    <div class="input">
                        <div class="label">Car Model</div>
                        <input type="text" placeholder="Enter your car model" v-model="car_model">
                    </div>
                    <div class="input">
                        <div class="label">Year</div>
                        <input type="text" placeholder="Enter your car year" v-model="car_year">
                    </div>
                    <div class="input">
                        <div class="label">License Plate Number</div>
                        <input type="text" placeholder="Enter your car license plate number" v-model="plate_number">
                    </div>
                    <div class="input">
                        <div class="label">Vehicle Identification Number</div>
                        <input type="text" placeholder="Enter your vehicle identification number" v-model="vin">
                    </div>
                    <div class="input">
                        <div class="label">Consent for a Background Check</div>
                        <select v-model="bg_check">
                            <option value="" selected disabled>Select yes/no</option>
                            <option value="no">No</option>
                            <option value="yes">Yes</option>
                        </select>
                    </div>


                    <div class="form_label">Payment Information</div>
                    <div class="input">
                        <div class="label">Bank Name</div>
                        <input type="text" placeholder="Enter bank name" v-model="bank_name">
                    </div>
                    <div class="input">
                        <div class="label">Account Name</div>
                        <input type="text" placeholder="Enter account name" v-model="acct_name">
                    </div>
                    <div class="input">
                        <div class="label">Account Number</div>
                        <input type="text" placeholder="Enter account number" v-model="acct_number">
                    </div>

                    
                    <div class="form_label">Emergency Contact Information </div>
                    <div class="input">
                        <div class="label">Emergency Contact Name</div>
                        <input type="text" placeholder="Enter contact name" v-model="emergency_contact_name">
                    </div>
                    <div class="input">
                        <div class="label">Relationship</div>
                        <input type="text" placeholder="Enter relationship" v-model="emergency_relationship">
                    </div>
                    <div class="input">
                        <div class="label">Emergency Contact Phone Number</div>
                        <input type="text" placeholder="Enter contact name" v-model="emergency_contact_phone_number">
                    </div>

                    <div class="bold">
                        Please confirm that the information provided is accurate and truthful.
                    </div>


                    <button_loading :height="'46px'" v-if="loading"/>
                    <div class="btn" @click="proceed()" v-else>Proceed</div>
                </div>
            </div>
        </div>
        
        <footerVue />
    </div>
</template>

<script setup> 
import headerVue from "@/components/header.vue";

import button_loading from '@/components/loading.vue'

import footerVue from "@/components/footer.vue";

import { inject, ref } from "vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

const states = ref([
    "Abia",
    "Adamawa",
    "Akwa Ibom",
    "Anambra",
    "Bauchi",
    "Bayelsa",
    "Benue",
    "Borno",
    "Cross River",
    "Delta",
    "Ebonyi",
    "Edo",
    "Ekiti",
    "Enugu",
    "Gombe",
    "Imo",
    "Jigawa",
    "Kaduna",
    "Kano",
    "Katsina",
    "Kebbi",
    "Kogi",
    "Kwara",
    "Lagos",
    "Nasarawa",
    "Niger",
    "Ogun",
    "Ondo",
    "Osun",
    "Oyo",
    "Plateau",
    "Rivers",
    "Sokoto",
    "Taraba",
    "Yobe",
    "Zamfara",
    "Federal Capital Territory"
])

const email = ref('')
const name = ref('')
const d_o_b = ref('')
const gender = ref('')
const phone = ref('')
const address = ref('')
const state = ref('')
const city = ref('')

const driver_license = ref('')

const car_make = ref('')
const car_model = ref('')
const car_year = ref('')
const plate_number = ref('')
const vin = ref('')
const bg_check = ref('')

const bank_name = ref('')
const acct_name = ref('')
const acct_number = ref('')

const emergency_contact_name = ref('')
const emergency_relationship = ref('')
const emergency_contact_phone_number = ref('')


const loading = ref(false)

const toast = useToast()
const axios = inject('axios')
const router = useRouter()

const proceed = () => {
    if (email.value == '' || name.value == '' || d_o_b.value == '' || gender.value == '' || phone.value == '' || 
        address.value == '' || state.value == '' || city.value == '' ||

        driver_license.value == '' ||

        car_make.value == '' || car_model.value == '' || car_year.value == '' || plate_number.value == '' || 
        vin.value == '' || bg_check.value == '' ||

        bank_name.value == '' || acct_name.value == '' || acct_number.value == '' ||

        emergency_contact_name.value == '' || emergency_relationship.value == '' || emergency_contact_phone_number.value == ''
    ) {
        toast.error('Make sure all field are filled')
        return
    }

    loading.value = true

    let form = new FormData()
    form.append('email', email.value)
    form.append('name', name.value)
    form.append('d_o_b', d_o_b.value)
    form.append('gender', gender.value)
    form.append('phone', phone.value)
    form.append('address', address.value)
    form.append('state', state.value)
    form.append('city', city.value)

    form.append('driver_license', driver_license.value)

    form.append('car_make', car_make.value)
    form.append('car_model', car_model.value)
    form.append('car_year', car_year.value)
    form.append('plate_number', plate_number.value)
    form.append('vin', vin.value)
    form.append('bg_check', bg_check.value)

    form.append('bank_name', bank_name.value)
    form.append('acct_name', acct_name.value)
    form.append('acct_number', acct_number.value)

    form.append('emergency_contact_name', emergency_contact_name.value)
    form.append('emergency_relationship', emergency_relationship.value)
    form.append('emergency_contact_phone_number', emergency_contact_phone_number.value)

    axios.post('/car/new', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            toast.success('Your car has been submitted, you will be contacted soon')

            setTimeout(() => {
                router.go()
            }, 5000);
        }
    })
}


</script>

<style scoped> 
    .main {
        padding: 50px 20px;
        padding-bottom: 50px;
    }
    .title {
        text-align: center;
        font-size: 25px;
        font-weight: 700;
    }
    .text {
        text-align: center;
        font-size: 14px;
    }

    .forms {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;
        margin-top: 40px;
    }
    @media screen and (max-width: 1050px) {
        .forms {
            flex-direction: column;
        }
    }

    .form {
        /* border: 1px solid #ddd; */
        background-color: #f8f8f8;
        border-radius: 20px;
        padding: 30px;
        box-sizing: border-box;

        max-width: 550px;
        width: 100%;
        margin: 0 auto;
    }
    .form_label {
        background-color: var(--main);
        color: white;
        padding: 15px 30px;
        padding-left: 30px;
        margin-left: -30px;
        margin-bottom: 20px;
        width: fit-content;
    }

    .input {
        margin-bottom: 20px;
    }
    .label {
        font-size: 14px;
        font-weight: 500;
        /* color: gray; */
        padding-left: 5px;
        margin-bottom: 5px;
    }
    input, select, textarea {
        padding: 15px;
        border: 1px solid #ddd;
        border: none;
        border-radius: 5px;
        width: 100%;
        box-sizing: border-box;
    }
    .bold {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .btn {
        padding: 12px;
        border-radius: 5px;
        background-color: var(--main);
        cursor: pointer;
        color: white;
        margin: 0 auto;
        text-align: center;
        font-weight: 500;
    }


    .head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .form_title {
        font-size: 18px;
        font-weight: 700;
    }
    .add {
        background-color: var(--main);
        color: white;
        padding: 10px 25px;
        border-radius: 5px;
        cursor: pointer;
    }
    .empty {
        padding: 30px 0px;
        text-align: center;
        display: flex;
        flex-direction: column;
        /* gap: 10px; */
        fill: #333;
        font-size: 14px;
        color: gray;
    }
</style>