<template>
    <div class="response">
        <div class="hh">
            <div class="tt">Wheeler Response</div>
            <div class="date">Date: {{moment(props.wheeler_response.created).format('Do MMMM YYYY - hh:mm A')}}</div>
        </div>

        <div class="mm">
            <div class="in">
                <div class="ll">Status</div>
                <select v-model="status">
                    <option value="active">Active</option>
                    <option value="done">Done</option>
                </select>
            </div>
            <div class="in">
                <div class="ll">Response</div>
                <textarea placeholder="Enter response" v-model="response"></textarea>
            </div>
            <div class="in">
                <div class="ll">Cost</div>
                <input type="number" placeholder="Enter shipment cost" v-model="cost">
            </div>

            <btn_loading :height="'46px'" v-if="loading"/>
            <div class="btn" @click="update()" v-else>Update</div>
        </div>

    </div>
</template>

<script setup>
import moment from 'moment';
import { defineProps, defineEmits, inject, ref } from 'vue';
import { useToast } from 'vue-toastification';

import btn_loading from '@/components/loading.vue'

const props = defineProps(['wheeler_response'])
const emit = defineEmits(['updated'])

const status = ref('')
const response = ref('')
const cost = ref('')

const init = () => {
    status.value = props.wheeler_response.status
    response.value = props.wheeler_response.response
    cost.value = props.wheeler_response.cost
}
init()

const loading = ref(false)

const axios = inject('axios')
const toast = useToast()

const update = () => {
    loading.value = true

    let form = new FormData()

    form.append('shipment_id', props.wheeler_response.shipment_id)
    form.append('status', status.value)
    form.append('response', response.value)
    form.append('cost', cost.value)

    axios.post('/admin/shipment/update/response', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            toast.success('Response Updated!')

            setTimeout(() => {
                emit('updated')
            }, 500);
        }
    })
}

</script>

<style scoped>

.hh {
    padding: 20px;
    border-bottom: 1px solid #ddd;
}
.tt {
    font-weight: 600;
}
.date {
    font-size: 12px;
}

.mm {
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-sizing: border-box;
}

.in {
    margin-bottom: 20px;
}
.ll {
    font-size: 14px;
    font-weight: 500;
    padding: 0px 0px 5px 5px;
}
textarea {
    resize: none;
    height: 100px;
}
input, select, textarea {

    padding: 15px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
}

.btn {
    padding: 12px;
    border-radius: 5px;
    background-color: var(--main);
    cursor: pointer;
    color: white;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
}
</style>