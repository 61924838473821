<template>
    <div class="admin" v-if="ready">
        <div class="hh">
            <div class="tt">Wheeler Admin</div>
            <div class="cc" @click="logout()">Logout</div>
        </div>
        <div class="mm">
            <div class="ss">
                <router-link to="/admin">
                    <div class="nn" :class="[route.name == 'home' ? 'aa' : '']">
                        <div class="ii">
                            <home :size="'20px'" :style="{'height': '20px'}"/>
                        </div>
                        Home
                    </div>
                </router-link>

                <router-link to="/admin/shipments">
                    <div class="nn" :class="[route.name == 'shipments' ? 'aa' : '']">
                        <div class="ii">
                            <ship :size="'20px'" :style="{'height': '20px'}"/>
                        </div>
                        Shipments
                    </div>
                </router-link>
                <div class="nn" :class="[route.name == 'whatsapp' ? 'aa' : '']">
                    <div class="ii">
                        <whatsapp :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                    Whatsapp API
                </div>
            </div>
            <div class="mmm">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
const db = new Localbase('wheeler')

import home from '@/icons/home.vue'
import ship from '@/icons/ship.vue';
import whatsapp from '@/icons/whatsapp.vue';
import { useRoute, useRouter } from 'vue-router';
import { inject, ref } from 'vue';
import { useToast } from 'vue-toastification';

const route = useRoute()
const router = useRouter()
const axios = inject('axios')
const toast = useToast()

const ready = ref(false)


const logout = () => {
    db.collection('admin_auth').delete()
    .then(() => {
        toast.success('Logout success!')
        router.go()
    })
}


const init = () => {
    
    db.collection('admin_auth').doc({ id: 1 }).get().then( auth => {
        if ( auth == undefined ) {
            router.push('/admin/login')
        } else {
            axios.defaults.headers.common['Auth'] = auth.key
            setTimeout(() => {
                ready.value = true
            }, 1000)
        }
    })
}
init()

</script>

<style scoped>
    .admin {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }
    .hh {
        background-color: var(--main);
        padding: 20px;

        display: flex;
        align-items: center;
        justify-content: space-between;
        color: white;
    }
    .tt {
        font-weight: 800;
        font-size: 20px;
    }
    .cc {
        border: 2px solid #fff;
        padding: 5px 15px;
        border-radius: 5px;
        /* font-size: 14px; */
        font-weight: 500;
        cursor: pointer;
    }


    .mm {
        flex: auto;
        height: calc(100% - 76px);

        display: grid;
        grid-template-columns: max-content auto;
    }
    .ss {
        border-right: 1px solid #ddd;
        height: 100%;
        width: 300px;
        padding: 20px;
        box-sizing: border-box;
    }
    .nn {
        background-color: #f8f8f8;
        cursor: pointer;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 5px;
        font-size: 14px;

        display: flex;
        align-items: center;
        gap: 15px;
        /* border-bottom: 1px solid #ddd; */
    }
    .ii {
        fill: #333;
    }

    .aa {
        font-weight: 600;
        background-color: var(--main);
        color: white;
    }
    .aa .ii {
        fill: white;

    }

    .mmm {
        /* background-color: blue; */
        height: 100%;
        box-sizing: border-box;
        overflow: auto;
    }
</style>