<template>
    <div class="header">
        <router-link to="/">
            <div class="logo">
                <img src="../assets/wheelerng.webp"/>
            </div>
        </router-link>
        <div class="menu">
            <router-link to="/">
                <div class="item" :class="[route.name == 'home' ? 'a' : '']">Home</div>
            </router-link>
            <router-link to="/xpress">
                <div class="item" :class="[route.name == 'xpress' ? 'a' : '']">Xpress</div>
            </router-link>

            <div class="item">Quote</div>
            <router-link to="/track">
                <div class="item" :class="[route.name == 'track' ? 'a' : '']">Track</div>
            </router-link>
        </div>
        <div class="btn">List Your Car</div>

        <div class="menuicon">
            <menuVue :size="'20px'" :style="{'height': '20px'}"/>
        </div>
    </div>
</template>

<script setup>
import menuVue from "@/icons/menu.vue";
import { useRoute } from "vue-router";

const route = useRoute()

</script>

<style scoped>
    .header {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0.75rem 0px;

        position: sticky;
        top: 0px;
        z-index: 2;

        background-color: white;
        box-shadow: 0px 0px 10px #f3f3f3;
    }
    .logo img {
        width: 70px;
    }
    .menu {
        display: flex;
        gap: 1.25rem;
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .item {
        padding-bottom: 2px;
        transition: .1s;
        cursor: pointer;
    }
    .item:hover {
        color: var(--main);
    }
    .a {
        font-weight: 600;
        border-bottom: 1px solid var(--main);
    }

    .btn {
        height: 40px;
        padding: 0px 2rem;
        font-size: 1.125rem;

        background-color: var(--main);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 1000px;

        cursor: pointer;
    }
    .menuicon {

        width: 35px;
        height: 35px;
        border-radius: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        
        display: none;
        transition: .2s;
        cursor: pointer;
    }
    .menuicon:hover {
        background-color: var(--main);
        fill: white;
    }

    @media screen and (max-width: 800px) {
        .header {
            padding: 13px 20px;
            justify-content: space-between;
            gap: 20px;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
        .logo img {
            width: 50px;
        }
        .menu {
            display: none;
        }
        .btn {
            margin-left: auto;
            /* display: none; */
        }
        .menuicon {
            display: flex;
        }
    }
</style>