<template>
    <div class="shipment_details">
        <div class="hh">
            <div class="tt">Shipment Details</div>
            <div class="date">Date: {{ moment(props.shipment.created).format('Do MMMM YYYY - hh:mm A') }}</div>
        </div>
        
        <div class="grid">
            <div>
                <div class="tt">Sender Infomation</div>
                <div class="f">
                    <div class="ll">Email:</div>
                    <div class="vv">{{props.shipment.email}}</div>
                </div>
                <div class="f">
                    <div class="ll">Name:</div>
                    <div class="vv">{{props.shipment.name}}</div>
                </div>
                <div class="f">
                    <div class="ll">Phone:</div>
                    <div class="vv">{{props.shipment.phone}}</div>
                </div>
                <div class="f">
                    <div class="ll">Address:</div>
                    <div class="vv">{{props.shipment.address}}</div>
                </div>
                <div class="f">
                    <div class="ll">State:</div>
                    <div class="vv">{{props.shipment.state}}</div>
                </div>
                <div class="f">
                    <div class="ll">City:</div>
                    <div class="vv">{{props.shipment.city}}</div>
                </div>
            </div>
            <div>
                <div class="tt">Receiver Infomation</div>
                <div class="f">
                    <div class="ll">Name:</div>
                    <div class="vv">{{props.shipment.receiver_name}}</div>
                </div>
                <div class="f">
                    <div class="ll">Phone:</div>
                    <div class="vv">{{props.shipment.receiver_phone}}</div>
                </div>
                <div class="f">
                    <div class="ll">Address:</div>
                    <div class="vv">{{props.shipment.receiver_address}}</div>
                </div>
                <div class="f">
                    <div class="ll">State:</div>
                    <div class="vv">{{props.shipment.receiver_state}}</div>
                </div>
                <div class="f">
                    <div class="ll">City:</div>
                    <div class="vv">{{props.shipment.receiver_city}}</div>
                </div>
            </div>
            <div>
                <div class="tt">Items</div>
                <div class="f">
                    <div class="ll">Category:</div>
                    <div class="vv">{{props.shipment.item_category}}</div>
                </div>
                <div class="f">
                    <div class="ll">Name:</div>
                    <div class="vv">{{props.shipment.item_name}}</div>
                </div>
                <div class="f">
                    <div class="ll">Weight (KG):</div>
                    <div class="vv">{{props.shipment.item_weight}}</div>
                </div>
                <div class="f">
                    <div class="ll">Value (Naira):</div>
                    <div class="vv">{{props.shipment.item_value}}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
import moment from 'moment';

import { defineProps } from 'vue';

const props = defineProps(['shipment'])
</script>

<style scoped>
.shipment_details {
    /* padding: 20px; */
}

.hh {
    padding: 20px;
    border-bottom: 1px solid #ddd;
}
.tt {
    font-weight: 600;
}
.date {
    font-size: 12px;
}



.grid {
    padding: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
}
.tt {
    font-weight: 600;
}
.f {
    margin-top: 15px;
}
.ll {
    font-size: 14px;
    color: gray;
}
.vv {
    font-size: 14px;
    font-weight: 500;
}
</style>