<template>
    <div class="shipments">
        
        <div class="tt">Shipments</div>
        <div class="mm">
            <div class="ll" v-if="loading">
                <loader/>
            </div>
            <div v-else>
                <shipment_vue v-for="shipment in shipments" :key="shipment.shipment_id" :shipment="shipment"/>
            </div>
        </div>
    </div>
</template>

<script setup>

import loader from "@/components/loader.vue";
import shipment_vue from '@/components/admin/shipment/view.vue'

import { inject, ref } from "vue";

const axios = inject('axios')

const loading = ref(true)
const shipments = ref(null)

const init = () => {
    axios.post('/admin/shipments')
    .then(data => {
        let r = data.data
        if (r.success) {
            shipments.value = r.shipments
            loading.value = false
        }
    })
}
init()

</script>

<style scoped>

    .shipments {
        padding: 20px;
    }
    .tt {
        font-size: 18px;
        font-weight: 600;
    }

    .mm {
        margin-top: 20px;
    }
    .ll {
        margin-top: 40px;
    }
</style>