<template>
    <div class="transit">

        <div class="hh">
            <div class="title">In Transit</div>
            <div class="date">Date: {{moment(props.transit.created).format('Do MMM YYYY - hh:mm A')}}</div>
        </div>

        <div class="mm">
            <div>
                <div class="tt">Note</div>
                <div class="vv">{{ props.transit.note }}</div>
            </div>
            <div>
                <div class="tt">Items On Transit</div>
                <div class="vv" v-if="items == ''">····</div>
                <div class="ff" v-for="(item, i) in items" :key="i">
                    <div class="nn">{{ i + 1 }}.</div>
                    <div class="fff">
                        <div>
                            <div class="ll">Category</div>
                            <div class="vv">{{ item.category }}</div>
                        </div>
                        <div>
                            <div class="ll">Name</div>
                            <div class="vv">{{ item.name }}</div>
                        </div>
                        <div>
                            <div class="ll">Weight (KG)</div>
                            <div class="vv">{{ item.weight }}</div>
                        </div>
                        <div>
                            <div class="ll">Value (₦)</div>
                            <div class="vv">{{ item.value }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="tt">Destination Address</div>
                <div class="vv" v-if="props.transit.address == ''">····</div>
                <div class="vv" v-else>{{ props.transit.address }}</div>
            </div>
            <div>
                <div class="tt">Destination State</div>
                <div class="vv" v-if="props.transit.state == ''">····</div>
                <div class="vv" v-else>{{ props.transit.state }}</div>
            </div>
            <div>
                <div class="tt">Carrier Name</div>
                <div class="vv" v-if="props.transit.carrier_name == ''">····</div>
                <div class="vv" v-else>{{ props.transit.carrier_name }}</div>
            </div>
            <div>
                <div class="tt">Carrier Phone</div>
                <div class="vv" v-if="props.transit.carrier_phone == ''">····</div>
                <div class="vv" v-else>{{ props.transit.carrier_phone }}</div>
            </div>
        </div>
    </div>
</template>

<script setup>

import moment from 'moment';
import { ref, defineProps } from 'vue';

const props = defineProps(['transit'])

const items = ref('')

const init = () => {
    if (props.transit.items != '') {
        items.value = JSON.parse(props.transit.items)
    }
}
init()

</script>

<style scoped>
    
    
    .hh {
        padding: 20px;
        border-bottom: 1px solid #ddd;
    }
    .title {
        font-weight: 600;
    }
    .date {
        font-size: 12px;
    }

    .mm {
        padding: 20px;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .tt {
        font-weight: 600;
    }
    
    .vv {
        font-size: 14px;
    }

    .ff {
        display: flex;
        gap: 20px;
        margin-top: 20px;
    }
    .nn {
        font-size: 12px;
    }
    .fff {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }
    .ll {
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 5px;
    }
</style>