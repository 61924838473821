<template>
    <div class="response">
        <div class="hh">
            <div class="title">Wheeler Response</div>
            <div class="date">Date: {{moment(wheeler_response.created).format('Do MMM YYYY - hh:mm A')}}</div>
        </div>

        <div class="mm">
            <div>
                <div class="tt">Message</div>
                <div class="vv">{{ props.wheeler_response.response }}</div>
            </div>
            <div>
                <div class="tt">Shipment Cost</div>
                <div class="vv" v-if="props.wheeler_response.cost == '0'">····</div>
                <div class="vv" v-else>{{ props.wheeler_response.cost }}</div>
            </div>
        </div>


    </div>
</template>

<script setup>
import moment from 'moment';
import { defineProps } from 'vue';

const props = defineProps(['wheeler_response'])

</script>

<style scoped>
    
    .hh {
        padding: 20px;
        border-bottom: 1px solid #ddd;
    }
    .title {
        font-weight: 600;
    }
    .date {
        font-size: 12px;
    }

    .mm {
        padding: 20px;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .tt {
        font-weight: 600;
    }
    
    .vv {
        font-size: 14px;
    }
</style>