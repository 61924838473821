<template>
    <div class="shipment " :class="[is_open ? 'aa' : '']">
        <div class="f" @click="toggle_shipment()">
            <div class="ff">
                <div class="fff">
                    <div class="ll">ID:</div>
                    <div class="vv">{{props.shipment.shipment_id}}</div>
                </div>
                <div class="fff">
                    <div class="ll">Date:</div>
                    <div class="vv">{{ moment(props.shipment.created).format('DD/MM/YYYY  HH:mm' ) }}</div>
                </div>
            </div>
            <div class="ii" :class="[is_open ? 'rr' : '']">
                <arrow :size="'20px'" :style="{'height': '20px'}"/>
            </div>
        </div>
        <div v-if="is_open">
            <div class="lo" v-if="loading">
                <loader/>
            </div>
            <div class="mm" v-else>
                <div class="ss">
                    <div class="nn" @click="open_shipment()" :class="[view == 'shipment' ? 'nn_aa' : '']">
                        <div class="icon">
                            <check :size="'20px'" :style="{'height': '20px'}" v-if="props.shipment.status == 'done'"/>
                            <pending :size="'20px'" :style="{'height': '20px'}" v-if="props.shipment.status == 'active'"/>
                            <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="props.shipment.status == ''"/>
                        </div>
                        Shipment
                    </div>
                    <div class="nn" @click="open_response()" :class="[view == 'response' ? 'nn_aa' : '']">
                        <div class="icon">
                            <check :size="'20px'" :style="{'height': '20px'}" v-if="wheeler_response.status == 'done'"/>
                            <pending :size="'20px'" :style="{'height': '20px'}" v-if="wheeler_response.status == 'active'"/>
                            <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="wheeler_response.status == ''"/>
                        </div>
                        Response
                    </div>
                    <div class="nn" @click="open_items_picked()" :class="[view == 'items_picked' ? 'nn_aa' : '']">
                        <div class="icon">
                            <check :size="'20px'" :style="{'height': '20px'}" v-if="items_picked.status == 'done'"/>
                            <pending :size="'20px'" :style="{'height': '20px'}" v-if="items_picked.status == 'active'"/>
                            <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="items_picked.status == ''"/>
                        </div>
                        Items Picked
                    </div>
                    <div class="nn" @click="open_delivering()" :class="[view == 'delivering' ? 'nn_aa' : '']">
                        <div class="icon">
                            <check :size="'20px'" :style="{'height': '20px'}" v-if="delivering.status == 'done'"/>
                            <pending :size="'20px'" :style="{'height': '20px'}" v-if="delivering.status == 'active'"/>
                            <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="delivering.status == ''"/>
                        </div>
                        Ready
                    </div>
                    <div class="nn" @click="open_transit()" :class="[view == 'transit' ? 'nn_aa' : '']">
                        <div class="icon">
                            <check :size="'20px'" :style="{'height': '20px'}" v-if="transit.status == 'done'"/>
                            <pending :size="'20px'" :style="{'height': '20px'}" v-if="transit.status == 'active'"/>
                            <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="transit.status == ''"/>
                        </div>
                        Transit
                    </div>
                    <div class="nn" @click="open_delivered()" :class="[view == 'delivered' ? 'nn_aa' : '']">
                        <div class="icon">
                            <check :size="'20px'" :style="{'height': '20px'}" v-if="delivered.status == 'done'"/>
                            <pending :size="'20px'" :style="{'height': '20px'}" v-if="delivered.status == 'active'"/>
                            <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="delivered.status == ''"/>
                        </div>
                        Delivered
                    </div>
                    <div class="nn" @click="open_received()" :class="[view == 'received' ? 'nn_aa' : '']">
                        <div class="icon">
                            <check :size="'20px'" :style="{'height': '20px'}" v-if="received.status == 'done'"/>
                            <pending :size="'20px'" :style="{'height': '20px'}" v-if="received.status == 'active'"/>
                            <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="received.status == ''"/>
                        </div>
                        Recived
                    </div>
                </div>
                <div class="mmm">
                    <shipment_vue v-show="view == 'shipment'" :shipment="props.shipment"/>
                    <response_vue v-show="view == 'response'" :wheeler_response="wheeler_response" @updated="updated()"/>
                    <items_picked_vue v-show="view == 'items_picked'" :items_picked="items_picked" @updated="updated()"/>
                    <delivering_vue v-show="view == 'delivering'" :delivering="delivering" @updated="updated()"/>
                    <transit_vue v-show="view == 'transit'" :transit="transit" @updated="updated()"/>
                    <delivered_vue v-show="view == 'delivered'" :delivered="delivered" @updated="updated()"/>
                    <received_vue v-show="view == 'received'" :received="received" @updated="updated()"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import moment from "moment-timezone";
import arrow from "@/icons/arrow.vue";

import loader from "@/components/loader.vue";

import check from "@/icons/check.vue";
import pending from "@/icons/pending.vue";
import circle_vue from "@/icons/circle.vue";

import shipment_vue from "@/components/admin/shipment/shipment.vue";
import response_vue from "./response.vue";
import items_picked_vue from "./items_picked.vue";
import delivering_vue from "./delivering.vue";
import transit_vue from "./transit.vue";
import delivered_vue from './delivered.vue'
import received_vue from './received.vue'

import {defineProps, inject, ref} from 'vue'
import { useToast } from "vue-toastification";

const props = defineProps(['shipment'])

const axios = inject('axios')
const toast = useToast()

const wheeler_response = ref(null)
const items_picked = ref(null)
const delivering = ref(null)
const transit = ref(null)
const delivered = ref(null)
const received = ref(null)

const loading = ref(false)
const loaded = ref(false)

const is_open = ref(false)

const toggle_shipment = () => {

    is_open.value = !is_open.value

    if (loaded.value) {
        return
    }
    fetch_shipment()
}

const updated = () => {
    fetch_shipment()
}

const fetch_shipment = () => {

    loading.value = true

    let form = new FormData()
    form.append('shipment_id', props.shipment.shipment_id)

    axios.post('/admin/shipment', form)
    .then(data => {
        let r = data.data
        if (r.success) {

            wheeler_response.value = r.wheeler_response
            items_picked.value = r.items_picked
            delivering.value = r.delivering
            transit.value = r.transit
            delivered.value = r.delivered
            received.value = r.received

            loaded.value = true
            loading.value = false

        }
    })
}

const view = ref('shipment')

const open_shipment = () => {
    if (props.shipment.status != '') {
        view.value = 'shipment'
    } else {
        toast.info('Not yet active')
    }
}

const open_response = () => {
    if (wheeler_response.value.status != '') {
        view.value = 'response'
    } else {
        toast.info('Not yet active')
    }
}

const open_items_picked = () => {
    if (items_picked.value.status != '') {
        view.value = 'items_picked'
    } else {
        toast.info('Not yet active')
    }
}

const open_delivering = () => {
    if (delivering.value.status != '') {
        view.value = 'delivering'
        console.log(view.value);
    } else {
        toast.info('Not yet active')
    }
}

const open_transit = () => {
    if (transit.value.status != '') {
        view.value = 'transit'
        console.log(view.value);
    } else {
        toast.info('Not yet active')
    }
}

const open_delivered = () => {
    if (delivered.value.status != '') {
        view.value = 'delivered'
        console.log(view.value);
    } else {
        toast.info('Not yet active')
    }
}

const open_received = () => {
    if (received.value.status != '') {
        view.value = 'received'
        console.log(view.value);
    } else {
        toast.info('Not yet active')
    }
}

</script>

<style scoped>
.shipment {

}
.f {
    cursor: pointer;
    padding: 15px;
    border-bottom: 1px solid #ddd;

    display: flex;
    align-items: center;
}
.ff {
    display: flex;
    align-items: center;
    gap: 20px;
}
.fff {
    display: flex;
    gap: 10px;
    font-size: 14px;
}
.ll {
    color: gray;
}
.vv {
    font-weight: 500;
}
.ii {
    margin-left: auto;
    transition: .2s;
}
.rr {
    transform: rotate(90deg)
}


.lo {
    margin: 20px 0px;
}


.mm {
    display: grid;
    grid-template-columns: max-content auto;
}
.ss {
    width: 200px;
    padding: 20px;
    padding-bottom: 0px;
    border-right: 1px solid #ddd;
}
.nn {
    font-size: 14px;
    padding: 15px;
    background-color: #f8f8f8;
    margin-bottom: 20px;
    border-radius: 5px;

    display: flex;
    align-items: center;
    gap: 10px;
    fill: #333;

    cursor: pointer;
}

.nn_aa {
    background-color: var(--main);
    color: white;
    fill: white;
}


.aa {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    margin: 20px 0px;

}
.aa .f {
    background-color: var(--main);
    color: white;
    border-bottom-color: var(--main);
}
.aa .ll {
    color: white;
}
.aa .ii {
    fill: white;
}

</style>