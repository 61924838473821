<template>
    <div :style="{'--main': c.main, '--trans': c.trans}">
        <router-view/>
    </div>
</template>

<script setup>

import { ref } from 'vue'
const c = ref({
    main: 'rgb(76, 187, 165)',
    trans: 'rgba(76, 187, 165, 0.2)',
})

document.title = 'Wheeler Logistics'

</script>

<style>
@import 'https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap';

#app {
    font-family: "Source Sans 3", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    padding: 0px;
    margin: 0px;
}


a {
    color: inherit;
    text-decoration: none;
    width: fit-content;
}
a:active, a:focus {
    background-color: unset;
    color: unset;
    -webkit-tap-highlight-color: transparent;
}

textarea, input {
    font-size: 14px;
    font-family: "Source Sans 3", sans-serif;
}
</style>
