<template>
    <div class="admin">
        <div class="mm">
            <div class="tt">Admin Login</div>
            <div class="input">
                <div class="ll">Username:</div>
                <input type="text" placeholder="Enter username" v-model="username">
            </div>
            <div class="input">
                <div class="ll">Password:</div>
                <input type="password" placeholder="Enter password" v-model="password">
            </div>
            <btn_loading :height="'46px'" v-if="loading"/>
            <div class="btn" @click="login()" v-else>Login</div>
        </div>
    </div>
</template>

<script setup>
import Localbase from 'localbase'
const db = new Localbase('wheeler')

import { inject, ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

import btn_loading from "@/components/loading.vue";


const username = ref('')
const password = ref('')

const toast = useToast()
const axios = inject('axios')
const router = useRouter()

const loading = ref(false)

const login = () => {
    if (username.value == '' || password.value == '') {
        toast.error('Enter your username and password')
        return
    }

    loading.value = true

    let form = new FormData()
    form.append('username', username.value)
    form.append('password', password.value)

    axios.post('/admin/login', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            toast.success('Login success!')
            
            db.collection('admin_auth').add({
                'id': 1,
                'type': r.type,
                'key': r.session,
                'username': username.value
            }).then(() => {
                setTimeout(() => {
                    router.push('/admin')
                }, 1000);
            })

        } else {
            if (r.username) {
                toast.error('Incorrect username')
            } 
            if (r.password) {
                toast.error('Incorrect password')
            }

            loading.value = false
        }
    })
}


const init = () => {
    
    db.collection('admin_auth').doc({ id: 1 }).get().then( auth => {
        if ( auth == undefined ) {
            console.log('ll');
        } else {
            axios.defaults.headers.common['Auth'] = auth.key
            setTimeout(() => {
                router.push('/admin')
            }, 1000)
        }
    })
}
init()

</script>

<style scoped>
    .admin {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 100vh;
        padding: 20px;
        box-sizing: border-box;
    }
    .mm {
        background-color: #f8f8f8;
        max-width: 500px;
        width: 100%;

        padding: 30px;
        box-sizing: border-box;
        border-radius: 20px;
    }

    .tt {
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 600;
        text-align: center;
    }
    .input {
        margin-bottom: 20px;
    }
    .ll {
        font-size: 14px;
        padding: 0px 0px 5px 5px;
    }
    input {
        padding: 15px;
        width: 100%;
        box-sizing: border-box;
        border-radius: 5px;
        border: none;
    }
    .btn {
        padding: 12px;
        border-radius: 5px;
        background-color: var(--main);
        cursor: pointer;
        color: white;
        margin: 0 auto;
        text-align: center;
        font-weight: 500;
    }
</style>