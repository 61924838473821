import { createRouter, createWebHashHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import xpress from '../views/xpress.vue'
import track from '../views/track.vue'
import car_list from '../views/car_list.vue'

import admin_login from '../views/admin_login.vue'
import admin from '../views/admin/view.vue'
import admin_home from '../views/admin/home.vue'
import admin_shipments from '../views/admin/shipments.vue'

const routes = [
    {
        path: '/',
        name: 'homepage',
        component: HomeView
    },
    {
        path: '/xpress',
        name: 'xpress',
        component: xpress
    },
    {
        path: '/track',
        name: 'track',
        component: track
    },
    {
        path: '/list-your-car',
        name: 'car_list',
        component: car_list
    },
    {
        path: '/admin/login',
        name: 'admin_login',
        component: admin_login
    },
    {
        path: '/admin',
        name: 'admin',
        component: admin,
        children: [
            {
                path: '',
                name: 'home',
                component: admin_home
            },
            {
                path: 'shipments',
                name: 'shipments',
                component: admin_shipments
            }
        ]
    }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
