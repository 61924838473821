<template>
    <div class="response">
        <div class="hh">
            <div class="title">Item Received</div>
            <div class="date">Date: {{moment(received.created).format('Do MMM YYYY - hh:mm A')}}</div>
        </div>

        <div class="mm">
            <div>
                <div class="tt">Note</div>
                <div class="vv">{{ props.received.note }}</div>
            </div>
            <div>
                <div class="tt">Receiver Name</div>
                <div class="vv" v-if="props.received.name == ''">····</div>
                <div class="vv" v-else>{{ props.received.name }}</div>
            </div>
            <div>
                <div class="tt">Receiver Phone Number</div>
                <div class="vv" v-if="props.received.phone == ''">····</div>
                <div class="vv" v-else>{{ props.received.phone }}</div>
            </div>
            <div>
                <div class="tt">Images</div>
                <div class="vv" v-if="props.received.images == ''">····</div>
                <div class="vv" v-else>{{ props.received.images }}</div>
            </div>
        </div>


    </div>
</template>

<script setup>
import moment from 'moment';
import { defineProps } from 'vue';

const props = defineProps(['received'])

</script>

<style scoped>
    
    .hh {
        padding: 20px;
        border-bottom: 1px solid #ddd;
    }
    .title {
        font-weight: 600;
    }
    .date {
        font-size: 12px;
    }

    .mm {
        padding: 20px;

        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .tt {
        font-weight: 600;
    }
    
    .vv {
        font-size: 14px;
    }
</style>