<template>
    <div class="details">
        <div>
            <div class="ff_c">
                <div class="ff">
                    <div class="l">Shipment Id:</div>
                    <div class="v">{{props.shipment_id}}</div>
                </div>
                <div>|</div>
                <div class="ff">
                    <div class="l">Date:</div>
                    <div class="v">{{moment(props.shipment.created).format('Do MMM YYYY')}}</div>
                </div>
            </div>

            <div class="mm_c">
                <div class="ll_c" 
                    :class="[shipment.status == 'done' ? 'll_a' : '', shipment.status == 'active' ? 'll_p' : '']">
                    <div class="icon">
                        <check :size="'20px'" :style="{'height': '20px'}" v-if="shipment.status == 'done'"/>
                        <pending :size="'20px'" :style="{'height': '20px'}" v-if="shipment.status == 'active'"/>
                        <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="shipment.status == ''"/>
                    </div>
                    <div class="line"></div>
                    <div class="level">
                        <div class="level_ff" @click="toggle_shipment()" >
                            <div class="level_title">Shipment Submitted</div>
                            <div class="arr" :class="[is_shipment ? 'rot' : '']" >
                                <arrow :size="'20px'" :style="{'height': '20px'}"/>
                            </div>
                        </div>

                        <shipment_vue v-if="is_shipment" :shipment="shipment"/>
                    </div>
                </div>
                <div class="ll_c"
                    :class="[wheeler_response.status == 'done' ? 'll_a' : '', wheeler_response.status == 'active' ? 'll_p' : '']">
                    <div class="icon">
                        <check :size="'20px'" :style="{'height': '20px'}" v-if="wheeler_response.status == 'done'"/>
                        <pending :size="'20px'" :style="{'height': '20px'}" v-if="wheeler_response.status == 'active'"/>
                        <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="wheeler_response.status == ''"/>
                    </div>
                    <div class="line"></div>
                    <div class="level">
                        <div class="level_ff" @click="toggle_response()">
                            <div class="level_title">Wheeler Response</div>
                            <div class="arr" :class="[is_wheeler_response ? 'rot' : '']" >
                                <arrow :size="'20px'" :style="{'height': '20px'}"/>
                            </div>
                        </div>

                        <response_vue v-if="is_wheeler_response" :wheeler_response="wheeler_response"/>
                    </div>
                </div>
                <div class="ll_c"
                    :class="[items_picked.status == 'done' ? 'll_a' : '', items_picked.status == 'active' ? 'll_p' : '']">
                    <div class="icon">
                        <check :size="'20px'" :style="{'height': '20px'}" v-if="items_picked.status == 'done'"/>
                        <pending :size="'20px'" :style="{'height': '20px'}" v-if="items_picked.status == 'active'"/>
                        <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="items_picked.status == ''"/>
                    </div>
                    <div class="line"></div>
                    <div class="level">
                        <div class="level_ff" @click="toggle_picked()">
                            <div class="level_title">Items Picked Up</div>
                            <div class="arr" :class="[is_picked ? 'rot' : '']">
                                <arrow :size="'20px'" :style="{'height': '20px'}"/>
                            </div>
                        </div>

                        <items_picked_vue v-if="is_picked" :items_picked="items_picked"/>
                    </div>
                </div>
                <div class="ll_c"
                    :class="[delivering.status == 'done' ? 'll_a' : '', delivering.status == 'active' ? 'll_p' : '']">
                    <div class="icon">
                        <check :size="'20px'" :style="{'height': '20px'}" v-if="delivering.status == 'done'"/>
                        <pending :size="'20px'" :style="{'height': '20px'}" v-if="delivering.status == 'active'"/>
                        <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="delivering.status == ''"/>
                    </div>
                    <div class="line"></div>
                    <div class="level">
                        <div class="level_ff" @click="toggle_delivering()">
                            <div class="level_title">Ready For Delivering</div>
                            <div class="arr" :class="[is_delivering ? 'rot' : '']">
                                <arrow :size="'20px'" :style="{'height': '20px'}"/>
                            </div>
                        </div>

                        <delivering_vue v-if="is_delivering" :delivering="delivering"/>
                    </div>
                </div>
                <div class="ll_c"
                    :class="[transit.status == 'done' ? 'll_a' : '', transit.status == 'active' ? 'll_p' : '']">
                    <div class="icon">
                        <check :size="'20px'" :style="{'height': '20px'}" v-if="transit.status == 'done'"/>
                        <pending :size="'20px'" :style="{'height': '20px'}" v-if="transit.status == 'active'"/>
                        <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="transit.status == ''"/>
                    </div>
                    <div class="line"></div>
                    <div class="level">
                        <div class="level_ff" @click="toggle_transit()">
                            <div class="level_title">In Transit</div>
                            <div class="arr" :class="[is_transit ? 'rot' : '']">
                                <arrow :size="'20px'" :style="{'height': '20px'}"/>
                            </div>
                        </div>

                        <transit_vue v-if="is_transit" :transit="transit"/>
                    </div>
                </div>
                <div class="ll_c"
                    :class="[delivered.status == 'done' ? 'll_a' : '', delivered.status == 'active' ? 'll_p' : '']">
                    <div class="icon">
                        <check :size="'20px'" :style="{'height': '20px'}" v-if="delivered.status == 'done'"/>
                        <pending :size="'20px'" :style="{'height': '20px'}" v-if="delivered.status == 'active'"/>
                        <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="delivered.status == ''"/>
                    </div>
                    <div class="line"></div>
                    <div class="level">
                        <div class="level_ff" @click="toggle_delivered()">
                            <div class="level_title">Delivered</div>
                            <div class="arr" :class="[is_delivered ? 'rot' : '']">
                                <arrow :size="'20px'" :style="{'height': '20px'}"/>
                            </div>
                        </div>

                        <delivered_vue v-if="is_delivered" :delivered="delivered"/>
                    </div>
                </div>
                <div class="ll_c"
                    :class="[received.status == 'done' ? 'll_a' : '', received.status == 'active' ? 'll_p' : '']">
                    <div class="icon">
                        <check :size="'20px'" :style="{'height': '20px'}" v-if="received.status == 'done'"/>
                        <pending :size="'20px'" :style="{'height': '20px'}" v-if="received.status == 'active'"/>
                        <circle_vue :size="'20px'" :style="{'height': '20px'}" v-if="received.status == ''"/>
                    </div>
                    <div class="line"></div>
                    <div class="level">
                        <div class="level_ff" @click="toggle_received()">
                            <div class="level_title">Items Received</div>
                            <div class="arr" :class="[is_received ? 'rot' : '']">
                                <arrow :size="'20px'" :style="{'height': '20px'}"/>
                            </div>
                        </div>

                        <received_vue v-if="is_received" :received="received"/>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment'
import check from "@/icons/check.vue";
import pending from "@/icons/pending.vue";
import circle_vue from "@/icons/circle.vue";

import arrow from "@/icons/arrow.vue";

import shipment_vue from "./shipment.vue";
import response_vue from './response.vue'
import items_picked_vue from './items_picked.vue'
import delivering_vue from './delivering.vue'
import transit_vue from './transit.vue'
import delivered_vue from './delivered.vue'
import received_vue from './received.vue'

import {  defineProps, ref } from "vue";
import { useToast } from 'vue-toastification';

const props = defineProps([
    'shipment_id', 'shipment', 'wheeler_response', 'items_picked', 'delivering', 'transit', 'delivered', 'received'
])


const is_shipment = ref(false)
const is_wheeler_response = ref(false)
const is_picked = ref(false)
const is_delivering = ref(false)
const is_transit = ref(false)
const is_delivered = ref(false)
const is_received = ref(false)


const toast = useToast()


const toggle_shipment = () => {
    if (props.shipment.status != '') {
        is_shipment.value = !is_shipment.value
    }
}

const toggle_response = () => {
    if (props.wheeler_response.status != '') {
        is_wheeler_response.value = !is_wheeler_response.value
    }
}

const toggle_picked = () => {
    if (props.items_picked.status != '') {
        is_picked.value = !is_picked.value
    }
    else {
        not_yet()
    }
}

const toggle_delivering = () => {
    if (props.delivering.status != '') {
        is_delivering.value = !is_delivering.value
    }
    else {
        not_yet()
    }
}

const toggle_transit = () => {
    if (props.transit.status != '') {
        is_transit.value = !is_transit.value
    }
    else {
        not_yet()
    }
}

const toggle_delivered = () => {
    if (props.delivered.status != '') {
        is_delivered.value = !is_delivered.value
    }
    else {
        not_yet()
    }
}

const toggle_received = () => {
    if (props.received.status != '') {
        is_received.value = !is_received.value
    }
    else {
        not_yet()
    }
}

const not_yet = () => {
    toast.info('Not yet active')
}

</script>

<style scoped>
    .details {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
        margin-top: 30px;
    }
    .ff_c {
        background-color: var(--main);
        border-radius: 5px;
        color: white;
        padding: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
    }
    .ff {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;
        /* margin-top: 5px; */
    }
    .l {
        
    }
    .v {
        font-size: 16px;
        font-weight: 600;
    }

    .mm_c {
        margin-left: 10px;
        /* border-left: 2px solid #f8f8f8; */
    }
    .ll_c {
        margin-top: 20px;
        margin-left: -12px;
        display: grid;
        grid-template-columns: max-content max-content auto;
        align-items: center;
    }
    .line {
        background-color: #f8f8f8;
        width: 30px;
        height: 2px;
    }
    .level {
        flex: auto;
        border-radius: 5px;
        overflow: hidden;
        border: 2px solid #f8f8f8;
    }
    .level_ff {
        background-color: #f8f8f8;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 15px;
        cursor: pointer;
    }
    .level_title {
        font-size: 14px;
    }
    .icon {
        fill: #333;
    }
    .arr {
        transition: .2s;
    }
    .rot {
        transform: rotate(90deg)
    }



    .ll_a .line, .ll_p .line {
        background-color: var(--main);
    }
    .ll_a .level {
        border-color: var(--main);

    }
    .ll_a .level_ff {
        background-color: var(--main);
        color: white;
        fill: white;
    }
    .ll_a .icon, .ll_p .icon {
        fill: var(--main);
    }
    .ll_a .level_title, .ll_p .level_title {
        font-weight: 600;
    }
</style>