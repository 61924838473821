<template>
    <div class="footer">
        <div class="box">
            <div class="left">
                <div class="big">
                    Drive With Wheeler Today
                </div>
                <div class="small">Join the millions who trust Wheeler</div>
                <div class="image_flex">
                    <div class="image">
                        <img src="../assets/apple.webp"/>
                    </div>
                    <div class="image">
                        <img src="../assets/google.webp"/>
                    </div>
                </div>
            </div>
            <div>
                <div class="car">
                    <img src="../assets/car.webp"/>
                </div>
            </div>
        </div>
        <div class="flex">
            <div class="text">
                Stay up to date on all the latest news.
            </div>
            <div class="input">
                <input type="text" placeholder="Enter Email">
                <div class="click">></div>
            </div>
        </div>
        <div class="links_container">
            <div class="item">
                <div class="title">Company</div>
                <div class="links">
                    <div class="link">
                        <a href="https://www.wheelerng.com/about">
                            About Us
                        </a>
                    </div>
                    <div class="link">
                        <a href="https://www.wheelerng.com/">
                            Careers
                        </a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">Help</div>
                <div class="links">
                    <div class="link">
                        <a href="https://www.wheelerng.com/">
                            Help Center
                        </a>
                    </div>
                    <div class="link">
                        <a href="https://www.wheelerng.com/">
                            Customer Support
                        </a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">Legal</div>
                <div class="links">
                    <div class="link">
                        <a href="https://www.wheelerng.com/">
                            Terms & Conditions
                        </a>
                    </div>
                    <div class="link">
                        <a href="https://www.wheelerng.com/">
                            Privacy Policy
                        </a>
                    </div>
                    <div class="link">
                        <a href="https://www.wheelerng.com/">
                            Cookies Policy
                        </a>
                    </div>
                </div>
            </div>
            <div class="item">
                <div class="title">Socials</div>
                <div class="links">
                    <div class="link">
                        <a href="https://twitter.com/Wheeler_NG">
                            Twitter
                        </a>
                    </div>
                    <div class="link">
                        <a href="https://www.linkedin.com/in/wheeler-ng-2696812a3/">
                            LinkedIn
                        </a>
                    </div>
                    <div class="link">
                        <a href="https://www.instagram.com/wheeler_ng/">
                            Instagram
                        </a>
                    </div>
                    <div class="link">
                        <a href="https://www.facebook.com/WheelerNG/">
                            Facebook
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="info">
            <div class="logo">
                <img src="../assets/wheelerng.webp"/>
            </div>
            <div class="info_text">
                @Wheeler. All Rights Reserved.
            </div>
        </div>
        
    </div>
</template>

<script setup>

</script>

<style scoped>
    .footer {
        background-color: rgb(0, 0, 0);
        color: white;
        padding: 30px;
        margin-top: 200px;
    }
    .box {
        background-color: var(--main);
        max-width: 900px;
        width: 100%;

        display: grid;
        grid-template-columns: 50% 50%;
        margin: 0 auto;
        margin-top: -150px;

        align-items: center;

        border-radius: 10px;
        overflow: hidden;
    }

    .left {
        padding: 1.5rem;

        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }
    .big {
        font-size: 3rem;
        line-height: 3rem;
        font-weight: bold;
    }

    .image_flex {
        display: flex;
        gap: 1rem;
    }
    .image_flex img {
        max-width: 150px;
    }
    .car img {
        width: 100%;
    }


    .flex {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        max-width: 900px;
        gap: 30px;
        margin: 0 auto;
        margin-top: 70px;
    }
    .text {
        font-size: 30px;
    }
    .input {
        display: flex;
        flex: auto;
        border-radius: 8px;
        overflow: hidden;
    }
    input {
        padding: 15px;
        border: none;
        background-color: white;
        width: 100%;
    }
    .click {
        width: 50px;
        height: 50px;
        background-color: var(--main);
        color: white;

        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .links_container {
        border-top: 1px solid white;
        padding: 30px;
        box-sizing: border-box;

        display: flex;
        flex-wrap: wrap;
        gap: 50px;
        font-size: 15px;

        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;
    }
    .title {
        margin-bottom: 30px;
        font-weight: 500;
    }
    .links {
        display: flex;
        flex-direction: column;
        gap: 15px;
        opacity: 0.9;
        font-weight: 300;
    }


    .info {
        
        max-width: 1100px;
        width: 100%;
        margin: 0 auto;
        margin-top: 50px;

        padding: 30px;
        padding-top: 0px;
        box-sizing: border-box;

        display: flex;
        align-items: center;
        gap: 20px;
    }
    .logo img {
        width: 48px;
    }
    .info_text {
        font-weight: 300;
        font-size: 14px;
        opacity: .9;
    }

    @media screen and (max-width: 900px) {
        .big {
            font-size: 25px;
            line-height: 30px;
        }
        .box {
            grid-template-columns: 60% 40%;
            margin-top: -100px;
        }
        .footer {
            margin-top: 100px;
        }
    }

    @media screen and (max-width: 650px) {
        .box {
            grid-template-columns: auto;
            max-width: 400px;
        }
        .image_flex img {
            max-width: 100px;
        }

        .links_container, .info {
            padding: 30px 0px;
        }
    }
</style>