<template>
    <div class="items_picked">
        <div class="hh">
            <div class="tt">Items Picked</div>
            <div class="date">Date: {{moment(props.items_picked.created).format('Do MMMM YYYY - hh:mm A')}}</div>
        </div>
        <div class="mm">
            <div class="in">
                <div class="ll">Status</div>
                <select v-model="status">
                    <option value="active">Active</option>
                    <option value="done">Done</option>
                </select>
            </div>
            <div class="in">
                <div class="ll">Note</div>
                <textarea placeholder="Enter note" v-model="note"></textarea>
            </div>
            <div class="in in_ff">
                <div class="ll">Items Picked</div>
                <div class="ff" v-for="(item, i) in items" :key="i">
                    <div>
                        <div class="ll">Category</div>
                        <select v-model="items[i]['category']">
                            <option v-for="(c, i) in store.category" :value="c" :key="i">{{c}}</option>
                        </select>
                    </div>
                    <div>
                        <div class="ll">Name</div>
                        <input type="text" placeholder="Item name" v-model="items[i]['name']">
                    </div>
                    <div>
                        <div class="ll">Weight (KG)</div>
                        <input type="number" placeholder="Item weight" v-model="items[i]['weight']">
                    </div>
                    <div>
                        <div class="ll">Value (₦)</div>
                        <input type="number" placeholder="Item value" v-model="items[i]['value']">
                    </div>
                    <div class="ii" v-if="i > 0" @click="remove_item(i)">
                        <remove_vue :size="'20px'" :style="{'height': '20px'}"/>
                    </div>
                </div>
                <div class="btn" @click="add_item()">Add Item</div>
            </div>
            <div class="in">
                <div class="ll">Address</div>
                <input type="text" placeholder="Enter address" v-model="address">
            </div>
            <div class="in">
                <div class="ll">State</div>
                <select v-model="state">
                    <option v-for="(s, i) in store.states" :value="s" :key="i">{{s}}</option>
                </select>
            </div>
            <btn_loading :height="'47px'" v-if="loading"/>
            <div class="btn" @click="update()" v-else>Update</div>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';
import { ref, defineProps, defineEmits, inject } from 'vue';
import { useToast } from 'vue-toastification';

import { store } from '@/store/store.js'

import remove_vue from '@/icons/remove.vue';
import btn_loading from '@/components/loading.vue'

const props = defineProps(['items_picked'])
const emit = defineEmits(['updated'])

const status = ref('')
const note = ref('')
const items = ref([])
const address = ref('')
const state = ref('')

const init = () => {
    status.value = props.items_picked.status
    note.value = props.items_picked.note

    if (props.items_picked.items == '') {
        items.value = [
            {
                'category': '',
                'name': '',
                'weight': '',
                'value': ''
            }
        ]
    } else {
        items.value = JSON.parse(props.items_picked.items)
    }

    address.value = props.items_picked.address
    state.value = props.items_picked.state
}
init()

const add_item = () => {
    items.value.push({
        'category': '',
        'name': '',
        'weight': '',
        'value': ''
    })
}

const remove_item = (i) => {
    setTimeout(() => {
        items.value.splice(i, 1)
    }, 200);
}

const axios = inject('axios')
const toast = useToast()

const loading = ref(false)

const update = () => {
    if (status.value == '' || note.value == '' || address.value == '' || state.value == '') {
        toast.error('Make sure all field are filled')
        return
    }
    for (let item of items.value) {
        for (let k in item) {
            if (item[k] == '') {
                console.log(item);
                toast.error('Make sure all field are filled')
                return
            }
        }
    }

    loading.value = true

    let form = new FormData()
    form.append('shipment_id', props.items_picked.shipment_id)
    form.append('status', status.value)
    form.append('note', note.value)
    form.append('items', JSON.stringify(items.value))
    form.append('address', address.value)
    form.append('state', state.value)

    axios.post('/admin/shipment/update/items_picked', form)
    .then(data => {
        let r = data.data
        if (r.success) {
            toast.success('Items Picked Updated!')

            setTimeout(() => {
                emit('updated')
            }, 500);
        }
    })
}

</script>

<style scoped>

.hh {
    padding: 20px;
    border-bottom: 1px solid #ddd;
}
.tt {
    font-weight: 600;
}
.date {
    font-size: 12px;
}


.mm {
    padding: 20px;
    box-sizing: border-box;
}

.in {
    margin-bottom: 20px;
}
.ll {
    font-size: 14px;
    font-weight: 500;
    padding: 0px 0px 5px 5px;
}
textarea {
    resize: none;
    height: 100px;
}
input, select, textarea {

    padding: 15px;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
}

.in_ff {
    width: fit-content
}
.ff {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}
.ii {
    cursor: pointer;
    margin-left: 20px;
}

.btn {
    padding: 12px;
    border-radius: 5px;
    background-color: var(--main);
    cursor: pointer;
    color: white;
    margin: 0 auto;
    text-align: center;
    font-weight: 500;
}
</style>